import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
export class ParecerCALFCAnswerDefaultComponent {
    constructor(wopGetAllPublic, pessoaService, imagemService, paisService, estadoService, cidadeService, elementoGrupoDominioService, elementoGrupoDominioLocalService, missaoService, swtAlert2Service, usuarioService, pessoaBuilder, pessoaInfoBuilder, dadoVocacionalService, dadoFormandoCAService, caminhoFormativoService, respostaParecerService, questionarioFormandoService, parecerResponsavelService, dadoFormandoCABuilder, caminhoFormativoFormandoBuilder, respostaParecerBuilder, questionarioFormandoBuilder, route, router) {
        this.wopGetAllPublic = wopGetAllPublic;
        this.pessoaService = pessoaService;
        this.imagemService = imagemService;
        this.paisService = paisService;
        this.estadoService = estadoService;
        this.cidadeService = cidadeService;
        this.elementoGrupoDominioService = elementoGrupoDominioService;
        this.elementoGrupoDominioLocalService = elementoGrupoDominioLocalService;
        this.missaoService = missaoService;
        this.swtAlert2Service = swtAlert2Service;
        this.usuarioService = usuarioService;
        this.pessoaBuilder = pessoaBuilder;
        this.pessoaInfoBuilder = pessoaInfoBuilder;
        this.dadoVocacionalService = dadoVocacionalService;
        this.dadoFormandoCAService = dadoFormandoCAService;
        this.caminhoFormativoService = caminhoFormativoService;
        this.respostaParecerService = respostaParecerService;
        this.questionarioFormandoService = questionarioFormandoService;
        this.parecerResponsavelService = parecerResponsavelService;
        this.dadoFormandoCABuilder = dadoFormandoCABuilder;
        this.caminhoFormativoFormandoBuilder = caminhoFormativoFormandoBuilder;
        this.respostaParecerBuilder = respostaParecerBuilder;
        this.questionarioFormandoBuilder = questionarioFormandoBuilder;
        this.route = route;
        this.router = router;
        this.intlTelInputCalled = false;
        this.missoesVinculo = new Array();
        this.missoes = new Array();
        this.formasVida = new Array();
        this.niveisFormacao = new Array();
        this.dadoFormandoCA = this.dadoFormandoCABuilder.getInstance();
        this.caminhoFormativoFormando =
            this.caminhoFormativoFormandoBuilder.getInstance();
        this.imageChangedEvent = "";
        this.croppedImage = "";
        this.nextClicked = false;
        this.pessoaResponsavel = this.pessoaBuilder.getInstance();
        this.formando = this.pessoaBuilder.getInstance();
        this.urlBaseImagem = environment.imagem.urlBaseImagem;
        this.url = environment.usuario.selectPorUsername;
        this.questionarioFormando = this.questionarioFormandoBuilder.getInstance();
        this.tabNames = ["dados-pessoais-tab-content", "questionario-tab-content"];
        this.activeTabNumber = 0;
        this.resposta36 = respostaParecerBuilder.getInstance();
        this.resposta37 = respostaParecerBuilder.getInstance();
        this.resposta38 = respostaParecerBuilder.getInstance();
        this.resposta39 = respostaParecerBuilder.getInstance();
        this.resposta40 = respostaParecerBuilder.getInstance();
        this.resposta41 = respostaParecerBuilder.getInstance();
        this.resposta42 = respostaParecerBuilder.getInstance();
        this.resposta43 = respostaParecerBuilder.getInstance();
        this.resposta44 = respostaParecerBuilder.getInstance();
        this.resposta45 = respostaParecerBuilder.getInstance();
        this.resposta46 = respostaParecerBuilder.getInstance();
        this.resposta47 = respostaParecerBuilder.getInstance();
        this.resposta48 = respostaParecerBuilder.getInstance();
        this.resposta49 = respostaParecerBuilder.getInstance();
        this.resposta50 = respostaParecerBuilder.getInstance();
        this.resposta51 = respostaParecerBuilder.getInstance();
        this.resposta52 = respostaParecerBuilder.getInstance();
        this.resposta53 = respostaParecerBuilder.getInstance();
        this.resposta54 = respostaParecerBuilder.getInstance();
        this.resposta55 = respostaParecerBuilder.getInstance();
        this.resposta56 = respostaParecerBuilder.getInstance();
        this.resposta57 = respostaParecerBuilder.getInstance();
        this.resposta58 = respostaParecerBuilder.getInstance();
        this.resposta59 = respostaParecerBuilder.getInstance();
        this.resposta60 = respostaParecerBuilder.getInstance();
        this.resposta61 = respostaParecerBuilder.getInstance();
        this.resposta62 = respostaParecerBuilder.getInstance();
        this.resposta63 = respostaParecerBuilder.getInstance();
        this.resposta64 = respostaParecerBuilder.getInstance();
        this.resposta65 = respostaParecerBuilder.getInstance();
        this.resposta66 = respostaParecerBuilder.getInstance();
        this.resposta67 = respostaParecerBuilder.getInstance();
        this.resposta68 = respostaParecerBuilder.getInstance();
        this.resposta69 = respostaParecerBuilder.getInstance();
        this.resposta70 = respostaParecerBuilder.getInstance();
        this.resposta71 = respostaParecerBuilder.getInstance();
        this.resposta72 = respostaParecerBuilder.getInstance();
        this.resposta73 = respostaParecerBuilder.getInstance();
        this.resposta74 = respostaParecerBuilder.getInstance();
        this.resposta75 = respostaParecerBuilder.getInstance();
        this.resposta76 = respostaParecerBuilder.getInstance();
    }
    ngOnInit() {
        this.initialization();
    }
    imprimirValorDoForm() {
        console.log("Valor do Form", JSON.stringify(this.dadosPessoaisForm.value));
    }
    initialization() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.getUsuarioAtual();
            this.carregarFormasVida();
            this.carregarNiveisFormacao();
            yield this.carregarMissoesVinculo();
            this.parecerResponsavelId = Number(this.route.snapshot.paramMap.get("id"));
            yield this.carregarParecerResponsavel();
            yield this.carregarQuestionarioFormando();
            yield this.carregarPessoaFormando(this.questionarioFormando.pessoaId);
            this.pessoaResponsavel = yield this.carregarPessoaResponsavel(this.usuarioAtual.id);
            yield this.carregarDadoVocacional();
            yield this.carregarDadoFormandoCA();
            yield this.carregarCaminhoFormativo();
            yield this.carregarRespostas();
            // this.imprimirValorDoForm();
        });
    }
    getUsuarioAtual() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.usuarioService.getCurrentUser();
                this.usuarioAtual = response.entity;
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    carregarMissoesVinculo() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.wopGetAllPublic.getAllMissao();
                this.missoesVinculo = response.entity;
                this.missoesVinculo = this.missoesVinculo.sort((a, b) => a.nome.localeCompare(b.nome));
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarPessoaFormando(pessoaId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.pessoaService.buscaPorId(pessoaId);
                this.formando = response.entity;
                this.pessoaFormandoId = this.formando.id;
                if (this.formando.imagemId != null) {
                    let blogImage = yield this.getPessoaImagem(this.formando.imagemId);
                    this.imagemService.createImage(blogImage, this.formando);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarPessoaResponsavel(usuarioId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.pessoaService.buscaPorUsuarioId(usuarioId);
                return response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarFormasVida() {
        let buscaFormaVida = {
            grupoNome: "FORMA_VIDA",
        };
        this.elementoGrupoDominioService
            .buscarPorGrupoDominioNome(buscaFormaVida)
            .then((lista) => {
            this.formasVida = lista.entity;
        })
            .catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }
    carregarNiveisFormacao() {
        let busca = {
            grupoNome: "NIVEL_FORMACAO",
        };
        this.elementoGrupoDominioService
            .buscarPorGrupoDominioNome(busca)
            .then((lista) => {
            this.niveisFormacao = lista.entity;
        })
            .catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }
    carregarPaises() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.paisService.findAll();
                this.paises = response.entity;
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarEstados(paisId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let request = {
                    paisId: paisId,
                    nome: undefined,
                    pageNumber: 0,
                    quantityOfElements: 1000,
                };
                let response = yield this.estadoService.buscarEstados(request);
                this.estados = response.entity;
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarCidades(estadoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let request = {
                    estadoId: estadoId,
                    nome: undefined,
                    pageNumber: 0,
                    quantityOfElements: 1000,
                };
                let response = yield this.cidadeService.buscarCidades(request);
                this.cidades = response.entity;
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    logOnConsole(dadosPessoaisForm) {
        console.log(dadosPessoaisForm);
    }
    activateTab(tab) {
        $('.nav-tabs a[href="#' + tab + '"]').removeClass("disabled");
        $('.nav-tabs a[href="#' + tab + '"]').tab("show");
    }
    activateTab2(action) {
        if (action.localeCompare("previous") == 0) {
            if (this.activeTabNumber > 0) {
                this.activeTabNumber -= 1;
            }
        }
        else if (action.localeCompare("next") == 0) {
            if (this.activeTabNumber < this.tabNames.length - 1) {
                this.activeTabNumber += 1;
            }
        }
        $('.nav-tabs a[href="#' + this.tabNames[this.activeTabNumber] + '"]').removeClass("disabled");
        $('.nav-tabs a[href="#' + this.tabNames[this.activeTabNumber] + '"]').tab("show");
    }
    salvar() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.atualizarCaminhoFormativo();
            yield this.atualizarParecerResponsavel("INICIADO");
        });
    }
    fileChangeEvent(event) {
        this.imageChangedEvent = event;
        console.log(event);
    }
    imageCropped(event) {
        this.croppedImage = event.base64;
    }
    imageLoaded() {
        console.log("Imagem carregada");
    }
    cropperReady() {
        console.log("Imagem cortada");
    }
    loadImageFailed() {
        console.log("Carregamento da imagem falhou!");
    }
    carregarDadoVocacional() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.dadoVocacionalService.porPessoaId(this.formando.id);
                if (response.entity != undefined && response.entity != null) {
                    this.dadoVocacional = response.entity;
                    this.pessoaFormadorPessoalSelecionado =
                        this.pessoaInfoBuilder.getInstance();
                    this.pessoaFormadorPessoalSelecionado.nome =
                        this.dadoVocacional.formadorPessoalPessoaNome;
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    selecionarFormadorComunitario(pessoa) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            //this.dadoVocacional.formadorPessoalPessoaId = pessoa.id;
            //this.pessoaFormadorComunitarioSelecionado = pessoa;
            //$('#listagem-pessoa-formador-comunitario').modal('hide');
        });
    }
    getPessoaImagem(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.imagemService.getPessoaPhoto(id);
                return response.body;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarDadoFormandoCA() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.dadoFormandoCAService.porPessoaId(this.formando.id);
                if (response != null && response != undefined) {
                    this.dadoFormandoCA = response;
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarCaminhoFormativo() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.caminhoFormativoService.porPessoaId(this.formando.id);
                if (response != null && response != undefined) {
                    this.caminhoFormativoFormando = response;
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    atualizarCaminhoFormativo() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (this.caminhoFormativoFormando.id != null) {
                    let response = yield this.caminhoFormativoService.atualizar(this.caminhoFormativoFormando);
                    this.caminhoFormativoFormando = response;
                    this.swtAlert2Service.successAlert(response.message);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarRespostas() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.respostaParecerService.porPessoaId(this.pessoaFormandoId);
                if (response != null && response != undefined) {
                    this.respostas = response.content;
                    this.respostas.forEach((resposta) => {
                        switch (resposta.parecer_pergunta_id) {
                            case 36:
                                this.resposta36 = resposta;
                                break;
                            case 37:
                                this.resposta37 = resposta;
                                break;
                            case 38:
                                this.resposta38 = resposta;
                                break;
                            case 39:
                                this.resposta39 = resposta;
                                break;
                            case 40:
                                this.resposta40 = resposta;
                                break;
                            case 41:
                                this.resposta41 = resposta;
                                break;
                            case 42:
                                this.resposta42 = resposta;
                                break;
                            case 43:
                                this.resposta43 = resposta;
                                break;
                            case 44:
                                this.resposta44 = resposta;
                                break;
                            case 45:
                                this.resposta45 = resposta;
                                break;
                            case 46:
                                this.resposta46 = resposta;
                                break;
                            case 47:
                                this.resposta47 = resposta;
                                break;
                            case 48:
                                this.resposta48 = resposta;
                                break;
                            case 49:
                                this.resposta49 = resposta;
                                break;
                            case 50:
                                this.resposta50 = resposta;
                                break;
                            case 51:
                                this.resposta51 = resposta;
                                break;
                            case 52:
                                this.resposta52 = resposta;
                                break;
                            case 53:
                                this.resposta53 = resposta;
                                break;
                            case 54:
                                this.resposta54 = resposta;
                                break;
                            case 55:
                                this.resposta55 = resposta;
                                break;
                            case 56:
                                this.resposta56 = resposta;
                                break;
                            case 57:
                                this.resposta57 = resposta;
                                break;
                            case 58:
                                this.resposta58 = resposta;
                                break;
                            case 59:
                                this.resposta59 = resposta;
                                break;
                            case 60:
                                this.resposta60 = resposta;
                                break;
                            case 61:
                                this.resposta61 = resposta;
                                break;
                            case 62:
                                this.resposta62 = resposta;
                                break;
                            case 63:
                                this.resposta63 = resposta;
                                break;
                            case 64:
                                this.resposta64 = resposta;
                                break;
                            case 65:
                                this.resposta65 = resposta;
                                break;
                            case 66:
                                this.resposta66 = resposta;
                                break;
                            case 67:
                                this.resposta67 = resposta;
                                break;
                            case 68:
                                this.resposta68 = resposta;
                                break;
                            case 69:
                                this.resposta69 = resposta;
                                break;
                            case 70:
                                this.resposta70 = resposta;
                                break;
                            case 71:
                                this.resposta71 = resposta;
                                break;
                            case 72:
                                this.resposta72 = resposta;
                                break;
                            case 73:
                                this.resposta73 = resposta;
                                break;
                            case 74:
                                this.resposta74 = resposta;
                                break;
                            case 75:
                                this.resposta75 = resposta;
                                break;
                            case 76:
                                this.resposta76 = resposta;
                                break;
                        }
                    });
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarParecerResponsavel() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.parecerResponsavelService.buscarPorId(this.parecerResponsavelId);
                if (response != null && response != undefined) {
                    this.parecerResponsavel = response;
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarQuestionarioFormando() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.questionarioFormandoService.buscarPorId(this.parecerResponsavel.questionarioFormandoId);
                if (response != null && response != undefined) {
                    this.questionarioFormando = response;
                    switch (this.questionarioFormando.questionarioId) {
                        case 4:
                            this.questionarioNome = 'Pedido para as Primeiras Promessas - CA';
                            break;
                        case 5:
                            this.questionarioNome = 'Pedido para as Promessas Definitivas - CA';
                            break;
                        case 6:
                            this.questionarioNome = 'Postulantes/Ingresso no Discipulado - CA';
                            break;
                        case 7:
                            this.questionarioNome = 'Discipulos de Primeiro Ano - CA';
                            break;
                        case 8:
                            this.questionarioNome = 'Renovação das Promessas - CA';
                            break;
                        default:
                            this.questionarioNome = null;
                            break;
                    }
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    atualizarResposta(resposta) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                resposta.pessoaId = this.pessoaFormandoId;
                if (resposta.id == null) {
                    let response = yield this.respostaParecerService.cadastrar(resposta);
                    this.swtAlert2Service.successAlert(response.message);
                    return response;
                }
                else {
                    let response = yield this.respostaParecerService.atualizar(resposta.id, resposta);
                    this.swtAlert2Service.successAlert(response.message);
                    return response;
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    salvarQuestionario() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.respostaVazia(this.resposta36)) {
                this.resposta36.parecer_pergunta_id = 36;
                this.resposta36 = yield this.atualizarResposta(this.resposta36);
            }
            if (!this.respostaVazia(this.resposta37)) {
                this.resposta37.parecer_pergunta_id = 37;
                this.resposta37 = yield this.atualizarResposta(this.resposta37);
            }
            if (!this.respostaVazia(this.resposta38)) {
                this.resposta38.parecer_pergunta_id = 38;
                this.resposta38 = yield this.atualizarResposta(this.resposta38);
            }
            if (!this.respostaVazia(this.resposta39)) {
                this.resposta39.parecer_pergunta_id = 39;
                this.resposta39 = yield this.atualizarResposta(this.resposta39);
            }
            if (!this.respostaVazia(this.resposta40)) {
                this.resposta40.parecer_pergunta_id = 40;
                this.resposta40 = yield this.atualizarResposta(this.resposta40);
            }
            if (!this.respostaVazia(this.resposta41)) {
                this.resposta41.parecer_pergunta_id = 41;
                this.resposta41 = yield this.atualizarResposta(this.resposta41);
            }
            if (!this.respostaVazia(this.resposta42)) {
                this.resposta42.parecer_pergunta_id = 42;
                this.resposta42 = yield this.atualizarResposta(this.resposta42);
            }
            if (!this.respostaVazia(this.resposta43)) {
                this.resposta43.parecer_pergunta_id = 43;
                this.resposta43 = yield this.atualizarResposta(this.resposta43);
            }
            if (!this.respostaVazia(this.resposta44)) {
                this.resposta44.parecer_pergunta_id = 44;
                this.resposta44 = yield this.atualizarResposta(this.resposta44);
            }
            if (!this.respostaVazia(this.resposta45)) {
                this.resposta45.parecer_pergunta_id = 45;
                this.resposta45 = yield this.atualizarResposta(this.resposta45);
            }
            if (!this.respostaVazia(this.resposta46)) {
                this.resposta46.parecer_pergunta_id = 46;
                this.resposta46 = yield this.atualizarResposta(this.resposta46);
            }
            if (!this.respostaVazia(this.resposta47)) {
                this.resposta47.parecer_pergunta_id = 47;
                this.resposta47 = yield this.atualizarResposta(this.resposta47);
            }
            if (!this.respostaVazia(this.resposta48)) {
                this.resposta48.parecer_pergunta_id = 48;
                this.resposta48 = yield this.atualizarResposta(this.resposta48);
            }
            if (!this.respostaVazia(this.resposta49)) {
                this.resposta49.parecer_pergunta_id = 49;
                this.resposta49 = yield this.atualizarResposta(this.resposta49);
            }
            if (!this.respostaVazia(this.resposta50)) {
                this.resposta50.parecer_pergunta_id = 50;
                this.resposta50 = yield this.atualizarResposta(this.resposta50);
            }
            if (!this.respostaVazia(this.resposta51)) {
                this.resposta51.parecer_pergunta_id = 51;
                this.resposta51 = yield this.atualizarResposta(this.resposta51);
            }
            if (!this.respostaVazia(this.resposta52)) {
                this.resposta52.parecer_pergunta_id = 52;
                this.resposta52 = yield this.atualizarResposta(this.resposta52);
            }
            if (!this.respostaVazia(this.resposta53)) {
                this.resposta53.parecer_pergunta_id = 53;
                this.resposta53 = yield this.atualizarResposta(this.resposta53);
            }
            if (!this.respostaVazia(this.resposta54)) {
                this.resposta54.parecer_pergunta_id = 54;
                this.resposta54 = yield this.atualizarResposta(this.resposta54);
            }
            if (!this.respostaVazia(this.resposta55)) {
                this.resposta55.parecer_pergunta_id = 55;
                this.resposta55 = yield this.atualizarResposta(this.resposta55);
            }
            if (!this.respostaVazia(this.resposta56)) {
                this.resposta56.parecer_pergunta_id = 56;
                this.resposta56 = yield this.atualizarResposta(this.resposta56);
            }
            if (!this.respostaVazia(this.resposta57)) {
                this.resposta57.parecer_pergunta_id = 57;
                this.resposta57 = yield this.atualizarResposta(this.resposta57);
            }
            if (!this.respostaVazia(this.resposta58)) {
                this.resposta58.parecer_pergunta_id = 58;
                this.resposta58 = yield this.atualizarResposta(this.resposta58);
            }
            if (!this.respostaVazia(this.resposta59)) {
                this.resposta59.parecer_pergunta_id = 59;
                this.resposta59 = yield this.atualizarResposta(this.resposta59);
            }
            if (!this.respostaVazia(this.resposta60)) {
                this.resposta60.parecer_pergunta_id = 60;
                this.resposta60 = yield this.atualizarResposta(this.resposta60);
            }
            if (!this.respostaVazia(this.resposta61)) {
                this.resposta61.parecer_pergunta_id = 61;
                this.resposta61 = yield this.atualizarResposta(this.resposta61);
            }
            if (!this.respostaVazia(this.resposta62)) {
                this.resposta62.parecer_pergunta_id = 62;
                this.resposta62 = yield this.atualizarResposta(this.resposta62);
            }
            if (!this.respostaVazia(this.resposta63)) {
                this.resposta63.parecer_pergunta_id = 63;
                this.resposta63 = yield this.atualizarResposta(this.resposta63);
            }
            if (!this.respostaVazia(this.resposta64)) {
                this.resposta64.parecer_pergunta_id = 64;
                this.resposta64 = yield this.atualizarResposta(this.resposta64);
            }
            if (!this.respostaVazia(this.resposta65)) {
                this.resposta65.parecer_pergunta_id = 65;
                this.resposta65 = yield this.atualizarResposta(this.resposta65);
            }
            if (!this.respostaVazia(this.resposta66)) {
                this.resposta66.parecer_pergunta_id = 66;
                this.resposta66 = yield this.atualizarResposta(this.resposta66);
            }
            if (!this.respostaVazia(this.resposta67)) {
                this.resposta67.parecer_pergunta_id = 67;
                this.resposta67 = yield this.atualizarResposta(this.resposta67);
            }
            if (!this.respostaVazia(this.resposta68)) {
                this.resposta68.parecer_pergunta_id = 68;
                this.resposta68 = yield this.atualizarResposta(this.resposta68);
            }
            if (!this.respostaVazia(this.resposta69)) {
                this.resposta69.parecer_pergunta_id = 69;
                this.resposta69 = yield this.atualizarResposta(this.resposta69);
            }
            if (!this.respostaVazia(this.resposta70)) {
                this.resposta70.parecer_pergunta_id = 70;
                this.resposta70 = yield this.atualizarResposta(this.resposta70);
            }
            if (!this.respostaVazia(this.resposta71)) {
                this.resposta71.parecer_pergunta_id = 71;
                this.resposta71 = yield this.atualizarResposta(this.resposta71);
            }
            if (!this.respostaVazia(this.resposta72)) {
                this.resposta72.parecer_pergunta_id = 72;
                this.resposta72 = yield this.atualizarResposta(this.resposta72);
            }
            if (!this.respostaVazia(this.resposta73)) {
                this.resposta73.parecer_pergunta_id = 73;
                this.resposta73 = yield this.atualizarResposta(this.resposta73);
            }
            if (!this.respostaVazia(this.resposta74)) {
                this.resposta74.parecer_pergunta_id = 74;
                this.resposta74 = yield this.atualizarResposta(this.resposta74);
            }
            if (!this.respostaVazia(this.resposta75)) {
                this.resposta75.parecer_pergunta_id = 75;
                this.resposta75 = yield this.atualizarResposta(this.resposta75);
            }
            if (!this.respostaVazia(this.resposta76)) {
                this.resposta76.parecer_pergunta_id = 76;
                this.resposta76 = yield this.atualizarResposta(this.resposta76);
            }
            // let status = this.definirStatusQuestionario();
            const caminhoFormativoSugeridoFC = this.caminhoFormativoFormando.caminhoFormativoSugeridoFC;
            yield this.atualizarParecerResponsavel("INICIADO");
            if (!this.caminhoFormativoFormando.id) {
                yield this.carregarCaminhoFormativo();
                this.caminhoFormativoFormando.caminhoFormativoSugeridoFC =
                    caminhoFormativoSugeridoFC;
            }
            this.atualizarCaminhoFormativo();
        });
    }
    definirStatusQuestionario() {
        let status = "INICIADO";
        let todoRespondido = false;
        if (!this.respostaVazia(this.resposta36) &&
            !this.respostaVazia(this.resposta37) &&
            !this.respostaVazia(this.resposta38) &&
            !this.respostaVazia(this.resposta39) &&
            !this.respostaVazia(this.resposta40) &&
            !this.respostaVazia(this.resposta41) &&
            !this.respostaVazia(this.resposta42) &&
            !this.respostaVazia(this.resposta43) &&
            !this.respostaVazia(this.resposta44) &&
            !this.respostaVazia(this.resposta45) &&
            !this.respostaVazia(this.resposta46) &&
            !this.respostaVazia(this.resposta47) &&
            // !this.respostaVazia(this.resposta33) && - Aqui é o caminho formativo proposto pelo FC
            !this.respostaVazia(this.resposta48) &&
            !this.respostaVazia(this.resposta49) &&
            !this.respostaVazia(this.resposta40) &&
            !this.respostaVazia(this.resposta51) &&
            !this.respostaVazia(this.resposta52) &&
            !this.respostaVazia(this.resposta53) &&
            !this.respostaVazia(this.resposta54) &&
            !this.respostaVazia(this.resposta55) &&
            !this.respostaVazia(this.resposta56) &&
            !this.respostaVazia(this.resposta57) &&
            !this.respostaVazia(this.resposta58) &&
            !this.respostaVazia(this.resposta59) &&
            !this.respostaVazia(this.resposta60) &&
            !this.respostaVazia(this.resposta61) &&
            !this.respostaVazia(this.resposta62) &&
            !this.respostaVazia(this.resposta63) &&
            !this.respostaVazia(this.resposta64) &&
            !this.respostaVazia(this.resposta65) &&
            !this.respostaVazia(this.resposta66) &&
            !this.respostaVazia(this.resposta67) &&
            !this.respostaVazia(this.resposta68) &&
            !this.respostaVazia(this.resposta69) &&
            !this.respostaVazia(this.resposta70) &&
            !this.respostaVazia(this.resposta71) &&
            !this.respostaVazia(this.resposta72) &&
            !this.respostaVazia(this.resposta73) &&
            !this.respostaVazia(this.resposta74) &&
            !this.respostaVazia(this.resposta75) &&
            !this.respostaVazia(this.resposta76)) {
            todoRespondido = true;
        }
        if (todoRespondido) {
            status = "ENVIADO";
        }
        return status;
    }
    respostaVazia(resposta) {
        return (resposta.resposta == null ||
            resposta.resposta == undefined ||
            resposta.resposta == "");
    }
    enviarQuestionario() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.salvarQuestionario();
            yield this.atualizarParecerResponsavel("ENVIADO");
            this.router.navigate(["secured/parecer-agradecimento"]);
        });
    }
    atualizarParecerResponsavel(status) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (this.parecerResponsavel.id != null) {
                    this.parecerResponsavel.status = status;
                    if (status == "ENVIADO") {
                        this.parecerResponsavel.data_envio = new Date();
                    }
                    let response = yield this.parecerResponsavelService.atualizar(this.parecerResponsavel.id, this.parecerResponsavel);
                    this.parecerResponsavel = response;
                    this.swtAlert2Service.successAlert(response.message);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
}
